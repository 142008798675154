const uzLtn = {
  Apps: "Ilovalar",
  Version: "Versiya",
  "Modified date": "O'zgartirilgan sana",
  language: "Til",
  notifications: "Bildirishnomalar",
  account: "Akkaunt",
  profile: "Profil",
  "sign out": "Tizimdan chiqish",
  "sign in": "Tizimga kirish",
  Welcome: "Xush kelibsiz",
  "Sign in to your account to continue":
    "Davom etish uchun akkauntingizga kiring",
  Password: "Parol",
  Login: "Login",
  "Sign up": "Ro'yxatdan o'tish",
  "To register in the system, please call number":
    "Tizimda roʻyxatdan oʻtish uchun +998 (99) 999 99 99 raqamiga murojaat qiling.",
  day: "Kun",
  night: "Tun",
  "Page not found.": "Sahifa topilmadi.",
  "The page you are looking for might have been removed.":
    "Siz qidirayotgan sahifa oʻchirilgan boʻlishi mumkin.",
  "Return to website": "Veb-saytga qaytish",
  "Welcome back": "Xush kelibsiz",
  lang: "uz-latn",
  Dashboard: "Dashbord",
  "Something went wrong": "Kutilmagan xatolik yuz berdi",
  "Login is required": "Login talab qilinadi",
  "Password is required": "Parol talab qilinadi",
  "Log Out": "Chiqish",
  "Do you really want to log out": "Haqiqatan ham tizimdan chiqmoqchimisiz",
  Yes: "Ha",
  No: "Yo'q",
  Accept: "Tasdiqlash",
  Reject: "Rad qilish",
  Verification: "Tasdiqlash",
  "Confirmation of the fine": "Jarimani tasdiqlash",
  "Do you really confirm this fine": "Haqiqatan ham bu jarimani tasdiqlaysizmi",
  "Information about the fine": "Jarima haqida ma'lumot",
  Date: "Sana",
  "Fine type": "Huquqbuzarlik turi",
  Visual: "Vizual",
  "Check number": "Raqamni tekshirish",
  Location: "Joylashuv",
  Image: "Foto",
  Video: "Video",
  Size: "Hajmi",
  "No data for verification": "Tasdiqlash uchun maʼlumotlar yoʻq",
  "No video": "Video yo'q",
  "Do you really reject this fine":
    "Siz haqiqatan ham bu jarimani rad qilasizmi",
  Canceled: "Bekor qilindi",
  Confirmed: "Tasdiqlandi",
  Search: "Qidirish",
  Map: "Xarita",
  online: "onlayn",
  Online: "Onlayn",
  offline: "offlayn",
  Offline: "Offlayn",
  Settings: "Sozlamalar",
  Entrepreneurs: "Tadbirkorlar",
  Add: "Qo'shish",
  Update: "Yangilash",
  Directory: "Spravochnik",
  Appmanager: "Ilova boshqaruvi",
  "First Name": "Familya",
  "Last Name": "Ism",
  Designation: "Belgilanish",
  "Created Date": "Yaratilgan sana",
  Uptime: "Ish vaqti",
  Infos: "Ma'lumotlar",
  Errors: "Xatolar",
  FileManager: "Fayl boshqaruvi",
  UploadFile: "Faylni yuklash",
  CreateFolder: "Papka yaratish",
  Name: "Nomi",
  INN: "STIR",
  "Expiration Date": "Tugash sanasi",
  Status: "Status",
  Actions: "Amallar",
  "Add Entrepreneur": "Tadbirkor qo'shish",
  maximum: "Belgilarning soni {{num}} dan oshmasligi kerak",
  Required: "To'ldirish talab qilinadi",
  Edit: "Tahrirlash",
  Delete: "O'chirish",
  Cancel: "Bekor qilish",
  "Remove entrepreneur?": "Tadbirkor o'chirilsinmi?",
  "Are you sure you want to delete the?":
    "Haqiqatan ham {{name}}ni o'chirmoqchimisiz?",
  "Successfully edited": "Muvaffaqiyatli tahrirlandi",
  "Successfully added": "Muvaffaqiyatli qo'shildi",
  Removed: "Oʻchirildi",
  All: "Hammasi",
  "Less than 30 days left before contract expires":
    "Shartnoma muddati tugashiga 30 kundan kam vaqt qolgan",
  "Field must consist  9 or 14 numbers":
    "Maydon 9 yoki 14ta raqamdan iborat bo'lishi kerak",
  "Date must be greater than this date":
    "Sana bugungi sanadan katta bo'lishi kerak",
  Objects: "Obyektlar",
  Object: "Obyekt",
  Users: "Foydalanuvchilar",
  Username: "Login",
  "Full name": "Toʻliq ismi",
  Description: "Tavsif",
  "Phone number": "Telefon raqami",
  "Last seen": "Oxirgi tashrif",
  "Add User": "Foydalanuvchi qo'shish",
  "Add Object": "Obyekt qo‘shish",
  min: "Kamida {{num}}ta belgi bo'lsin",
  Region: "Hudud",
  Address: "Manzil",
  "Device types": "Qurilma turlari",
  Rules: "Qoida turlari",
  "Add device type": "Qurilma turini qo'shish",
  "Add rule": "Qoida qo'shish",
  "Only numbers": "Faqat raqamlar",
  "Device type": "Qurilma turi",
  "Last update": "Oxirgi yangilanish",
  Copy: "Nusxa ko‘chirish",
  Device: "Qurilma",
  "Add Computer": "Kompyuter qo'shish",
  "Invalid IP address format": "IP-adres formati noto‘g‘ri",
  "Please add the location on the map":
    "Iltimos, xaritada joylashuvni qo'shing",
  Computers: "Kompyuterlar",
  "Add Camera": "Kamera qo'shish",
  "No data": "Maʼlumot yoʻq",
  "Car number": "Avtomobil raqami",
  Dislocation: "Dislokatsiya",
  Rule: "Qoida",
  DRB: "DRB",
  From: "Dan",
  To: "Gacha",
  Cameras: "Kameralar",
  Comment: "Izoh",
  Code: "Kod",
  "Open in telegram": "Telegramda oching",
  "Search by ID": "ID bo'yicha qidirish",
  Information: "Ma'lumot",
  paginationFooter: "Jami: {{num1}} tadan {{num}} ta",
  View: "Ko'rish",
  Restore: "Qayta tiklash",
  "Enter your password": "Parolingizni kiriting",
  Successfully: "Muvaffaqiyatli",
  Staff: "Xodimlar",
  Administrators: "Administratorlar",
  Operators: "Operatorlar",
  Source: "Manba",
  "Select user from NTLM": "NTLM dan foydalanuvchini tanlang",
  "Fill the form": "Shaklni to'ldiring",
  Next: "Keyingi",
  Back: "Orqaga",
  Skip: "Oʻtkazib yuborish",
  Optional: "Ixtiyoriy",
  Devices: "Qurilmalar",
  "Devices (V2)": "Qurilmalar (V2)",
  "Incorrect value": "Noto‘g‘ri qiymat",
  "Control (Police)": "Nazorat (YPX)",
  Control: "Nazorat",
  "Change rule": "Qoidani o'zgartirish",
  Permissions: "Ruxsatnomalar",
  "Enter a new title": "Yangi nom kiriting",
  // "Turn off": "To'xtatish",
  "YPX users": "Foydalanuvchilar (YPX)",
  "The time the statistics were taken": "Statistik ma'lumotlar olingan vaqt",
  Today: "Bugun",
  Email: "Elektron Pochta",
  Yesterday: "Kecha",
  Difference: "Farq",
  "Personal Information": "Shaxsiy ma'lumot",
  Violations: "Qoidabuzarliklar",
  "Cars in joint movement": "Bir yo'nalishda harakatdagi mashinalar",
  Speed: "Tezlik",
  "Stats by rules": "Qoidalar bo'yicha statistika",
  "Statistics of violation types by date":
    "Sanalar bo'yicha qoidabuzarlik turlari statistikasi",
  "Operator stats": "Operatorlar statistikasi",
  "Data not found": "Maʼlumot topilmadi",
  "Add API": "API qo‘shish",
  Bonus: "Bonus",
  "Bonus active": "Bonus aktiv",
  "Bonus passive": "Bonus passiv",
  "Location not found": "Joylashuv aniqlanmadi",
  Total: "Jami",
  sum: "so`m",
  "Active devices": "To‘liq ish holatida",
  "Warning devices": "Qisman nosozlik mavjud",
  "Error devices": "Maʼlumot yuborishda hatolik mavjud",
  "Disabled by admin": "Administrator tomonidan o‘chirilgan",
  "Disabled devices": "O‘chiq qurilmalar",
  YPX: "YPX",
  Moderators: "Moderatorlar",
  Engineers: "Texnik muhandislar",
  Worker: "Texnik hodim",
  Workers: "Texnik hodimlar",
  "Waiting status": "Tasdiqlanmagan",
  "Sending status": "Tasdiqlangan",
  "Sent status": "Qabul qilingan qoidabuzarliklar",
  "Rejected status": "Qaytgan qoidabuzarliklar",
  "Passive status": "Bekor qilinganlar",
  "Canceled status": "Yuborish bekor qilinganlar",
  Test: "Test",
  Sending: "Yuborilmoqda",
  Rejected: "Rad qilindi",
  Sent: "Yuborildi",
  Resend: "Qayta yuborish",
  "Access to control": "Nazoratga ruxsat",
  "Access to verification": "Tasdiqlashga ruxsat",
  "Access to search": "Qidirishga ruxsat",
  "Verification objects": "Tasdiqlash ob'ektlari",
  "Control objects": "Nazorat ob'ektlari",
  Memory: "Xotira",
  free: "Bo'sh",
  used: "Ishlatilgan",
  Network: "Tarmoq",
  CPU: "Markaziy protsessor",
  Disc: "Disk",
  "Cancel send": "Yuborishni bekor qilish",
  Maintain: "Maintain",
  "Change role": "Rolni o'zgartirish",
  Administrator: "Administrator",
  Boss: "Boss",
  Moderator: "Moderator",
  Operator: "Operator",
  Engineer: "Texnik muhandis",
  "Manage Operators": "Operatorlarni boshqarish",
  "Time interval": "Vaqt oralig'i",
  Monday: "Dushanba",
  Tuesday: "Seshanba",
  Wednesday: "Chorshanba",
  Thursday: "Payshanba ",
  Friday: "Juma",
  Saturday: "Shanba",
  Sunday: "Yakshanba",
  "Certificate Date": "Sertifikat sanasi",
  "Advanced search filters": "Kengaytirilgan qidiruv filtrlari",
  "Reason for rejection": "Rad qilish sababi",
  "Influence of an external factor": "Tashqi omil taʼsiri",
  "The number is invalid": "DRB yaroqsiz",
  "The number is not specified": "DRB aniqlanmagan",
  "The rule is not broken": "Qoida buzilmagan",
  Message: "Xabar",
  Type: "Turi",
  Brand: "Brend",
  Model: "Model",
  Color: "Rangi",
  Close: "Yopish",
  "Working hours": "Ish vaqti",
  Constructor: "Konstruktor",
  "LK uptime": "LK ish vaqti",
  Sender: "Yuboruvchi",
  "Sender uptime": "Yuboruvchining ish vaqti",
  Storage: "Hotira ombori",
  "System uptime": "Tizimning ish vaqti",
  Short: "Qisqacha",
  Detailed: "Batafsil",
  Exit: "Chiqish",
  "Copy table": "Jadvalni nusxalash",
  "Table copied": "Jadval nusxalandi",
  ago: "oldin",
  "day time": "кун",
  days: "kun",
  hour: "soat",
  hours: "soat",
  minute: "minut",
  minutes: "minut",
  second: "sekund",
  seconds: "sekund",
  "Change entrepreneur": "Tadbirkorni almashtirish",
  "Change computer": "Kompyuterni almashtirish",
  "Change object": "Ob'ektni almashtirish",
  Table: "Jadval",
  Chart: "Grafik",
  Report: "Hisobot",
  Lines: "Chiziqlar",
  Polygons: "Ko'pburchaklar",
  Points: "Nuqtalar",
  Clear: "Tozalash",
  Send: "Yuborish",
  Line: "Chiziq",
  Save: "Saqlash",
  DoubleLine: "Ikki chiziq",
  "Stop Line": "To'xtash chizig'i",
  "Stop sign": "To'xtash belgisi",
  "Red Polygon": "Qizil ko'pburchak",
  "Speed Polygon": "Tezlik ko'pburchagi",
  "Traffic light": "Svetofor",
  "Add Region": "Hudud qo'shish",
  Regions: "Hududlar",
  Province: "Viloyat",
  "Update photo": "Suratni yangilash",
  Warnings: "Ogohlantirishlar",
  Snapshots: "Snepshotlar",
  Configurator: "Konfigurator",
  "Operators statistics": "Operatorlar statistikasi",
  " Do you want save the changes you made to Configurator ?":
    "Konfiguratorga kiritilgan o'zgarishlarni saqlamoqchimisiz?",
  "Your changes will be lost if you don't save them!":
    "Agar siz ularni saqlamasangiz, o'zgartirishlaringiz yo'qoladi!",
  "Don't Save": "Saqlanmasin",
  Monitoring: "Monitoring",
  Radar: "Radar",
  Crossroads: "Chorraha",
  "Last rule": "Oxirgi qoida buzilishi",
  "Last registration": "So'nggi ro'yxatdan o'tish",
  "Last received data": "Oxirgi olingan ma'lumotlar",
  "Never worked": "Biror marta ishlamagan",
  "No contact": "Aloqa yo'q",
  "Everything is fine": "Hammasi yaxshi",
  "No violation": "Qoidabuzarlik yo'q",
  "No information": "Ma'lumot yo'q",
  "Recive/Send": "Qabul qilish/Yuborish",
  Comments: "Izohlar",
  Action: "Harakat",
  "Turn on": "Yoqish",
  "Turn off": "O'chirish",
  "Operator report": "Operator hisoboti",
  Active: "Aktiv",
  Passive: "Passiv",
  "Rules for verify": "Tasdiqlash uchun qoidalar",
  "Rules for no verify": "Tasdiqlanmaydigan qoidalar",
  "User info": "Foydalanuvchi ma'lumotlari",
  Content: "Mazmuni",
  Telegram: "Telegram",
  "Speed limit for trucks": "Yuk mashinalari uchun tezlik chegarasi",
  "Speed limit for buses": "Avtobuslar uchun tezlik chegarasi",
  Entrepreneur: "Tadbirkor",
  "Works at night": "Kechasi ishlaydi",
  "Accessed date": "Kirish sanasi",
  "Sended date": "Yuborilgan sana",
  "Report by camera (Violations)": "Kamera hisoboti (Qoidabuzarliklar)",
  Range: "Diapazon",
  Weekly: "Haftalik",
  Monthly: "Oylik",
  Daily: "Kundalik",
  "Number of violations": "Qoidabuzarliklar soni",
  "Show all": "Hammasini ko'rish",
  "Read all": "Ko'rildi",
  "No new notifications": "Yangi bildirishnomalar yo'q",
  "New notifications": "Yangi bildirishnomalar soni",
  "Operator Logs": "Operator Loglari",
  New: "Yangi",
  Old: "Eski",
  videoViewAlert: "Qoidabuzarlik aks etgan video to‘liq ko‘rilmagan",
  "Choose an entrepreneur": "Tadbirkorlardan birini tanlang",
  "Wrong format": "Noto'g'ri format",
  "Add car number": "DRB qo'shish",
  Yearly: "Yillik",
  Custom: "Maxsus",
  "Plate number may be at fault please check again!":
    "DRBda xatolik bo'lishi mumkin iltimos qayta tekshirib ko'ring!",
};

export default uzLtn;
