const decoder = (str, from, to) => {
  const a = Buffer.from(str, from);
  return a.toString(to);
};

const encoder = (str, from, to) => {
  const a = Buffer.from(str, from);
  return a.toString(to);
};

let id = "";

const resize = (cb, delay) => {
  if (typeof cb !== "function") return;

  if (!id) {
    clearTimeout(id);
    return;
  }
  id = setTimeout(() => {
    // todo
    cb();
    id = null;
  }, delay);
};

const superAdmin = () => {
  const user = JSON?.parse(localStorage?.getItem("user"));
  return user && user.user.id === 1 ? true : false;
};
const admin = () => {
  const user = JSON?.parse(localStorage?.getItem("user"));
  return user ? user.user?.group_id === 0 : false;
};
const moderator = () => {
  const user = JSON?.parse(localStorage?.getItem("user"));
  return user ? user.user?.group_id === -3 : false;
};
const operator = () => {
  const user = JSON?.parse(localStorage?.getItem("user"));
  return user ? user.user?.group_id === -1 : false;
};
const engineer = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? user.user?.group_id === -5 : false;
};
const tadbirkor = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? user.user?.group_id > 0 : false;
};
const GAI = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? user.user.group_id === -2 : false;
};

function isIpAddressOrDomain() {
  // Regular expression to match an IP address
  const ipPattern = /^(\d{1,3}\.){3}\d{1,3}$/;
  // Regular expression to match a domain name
  const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Check if the location.hostname matches either the IP address pattern or the domain name pattern
  if (
    ipPattern.test(window.location.hostname) &&
    (window.location.port >= 1800 && window.location.port <= 1900) === false
  ) {
    return `http://${window.location.hostname}:7070`;
  } else if (domainPattern.test(window.location.hostname)) {
    return `https://api.${window.location.hostname}`;
  } else if (window.location.port >= 1800 && window.location.port <= 1900) {
    return "https://api.lochinkoz.uz";
    // return "http://192.168.5.55:37780";
    // return "//192.168.5.7:37780";
    // return "//192.168.102.39:37780";
    // return "//192.168.2.13:37780";
  }
}

const handleContextMenu = (event) => {
  event.preventDefault();

  const imgElement = event.target;
  if (imgElement.tagName === "IMG") {
    imgElement.crossOrigin = "anonymous";

    // Create a new image element to copy
    const copiedImage = new Image();
    copiedImage.src = imgElement.src;

    // Create a temporary container to hold the image
    const container = document.createElement("div");
    container.appendChild(copiedImage);

    // Select and copy the container's contents to clipboard
    document.body.appendChild(container);
    const range = document.createRange();
    range.selectNode(container);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeRange(range);
    document.body.removeChild(container);

    // Optional: Provide feedback that the image was copied
    alert("Image copied to clipboard!");
  }
};

const formatter = Intl.NumberFormat("en", { notation: "compact" }); // e.g. 10k, 5M

const copyImageToClipboard = async (url) => {
  try {
    const response = await fetch(url);
    const imageBlob = await response.blob();
    const imageBase64 = await convertBlobToBase64(imageBlob);

    await navigator.clipboard?.write([
      // eslint-disable-next-line no-undef
      new ClipboardItem({
        "image/png": base64toBlob(imageBase64, "image/png"),
      }),
    ]);
  } catch (error) {
    console.error("Error copying image to clipboard:", error);
  }
};

// Function to convert Blob to Base64
const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// Function to convert Base64 to Blob
const base64toBlob = (base64, type) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64.split(",")[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type });
};

module.exports = {
  resize,
  decoder,
  encoder,
  admin,
  moderator,
  engineer,
  tadbirkor,
  operator,
  GAI,
  superAdmin,
  formatter,
  isIpAddressOrDomain,
  handleContextMenu,
  copyImageToClipboard,
};
